import { Helmet } from 'react-helmet';
import config from 'Config/AppConfig';

export const CookiesPolicyBanner = ({ autoBlockUrl = '', sdkUrl = '', sdkKey = '' }) => (
  <Helmet>
    {autoBlockUrl && <script type="text/javascript" src={autoBlockUrl}></script>}
    <script
      src={sdkUrl}
      data-document-language="true"
      type="text/javascript"
      charset="UTF-8"
      data-domain-script={sdkKey}
    ></script>
    <script type="text/javascript">function OptanonWrapper() {}</script>
  </Helmet>
);

export const withCookiesBanner = (Component) => (props) => {
  return (
    <>
      <CookiesPolicyBanner
        autoBlockUrl={config.cookiesAutoBlockUrl}
        sdkUrl={config.cookiesSdkUrl}
        sdkKey={config.cookiesSdkKey}
      />
      <Component {...props} />
    </>
  );
};
