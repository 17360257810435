import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import { compose } from 'ramda';
import { withCookiesBanner } from 'CookiesPolicyBanner';
import 'Legacy/main'
import './Config/TracingConfig'
import { store } from './Store'
import AppFrame from './Components/AppFrame'

import { ConnectedThemeProvider } from './Themes/connected-theme-provider'
// import { StyledToastContainer } from './Components/Toasts'

import '@scuf/common/honeywell-compact-combined/theme.css'
import '@scuf/datatable/honeywell-compact-combined/theme.css'
import './App.css'

function App () {
  return (
    <Provider store={store}>
      <ConnectedThemeProvider>
        <Fragment>
          <AppFrame />
        </Fragment>
      </ConnectedThemeProvider>
    </Provider>
  )
}

export default compose(
 withCookiesBanner
)(App);
