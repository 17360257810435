import get from 'lodash/get'

import {isValidJsonString} from 'Utils/property-helper'

let onPremVariables
const onPrem = global.localStorage.getItem('__OP_INTEL_RV__')
if (onPrem !== undefined && isValidJsonString(onPrem)) { 
  onPremVariables = JSON.parse(onPrem) 
}

const config = {
  debug: (process.env.REACT_APP_DEBUG_MODE === 'true'),
  api: {
    connectedaidc: get(onPremVariables, 'url.api_url', `${process.env.REACT_APP_API_URL}`),
    apiKey: process.env.REACT_APP_APIGEE_KEY
  },
  signallingServer: {
    socketURL: get(onPremVariables, 'url.ss_ws_url', `${process.env.REACT_APP_SIGNALLING_SERVER_WS_URL}`),
    apiURL: get(onPremVariables, 'url.ss_api_url', `${process.env.REACT_APP_SIGNALLING_SERVER_API_URL}`)
  },
  tracing: {
    appInsights: process.env.REACT_APP_APP_INSIGHTS
  },
  ssClientMinVersionFE: process.env.REACT_APP_SS_CLIENT_MIN_VERSION_FE,
  cookiesSdkUrl: process.env.REACT_APP_SDK_URL,
  cookiesAutoBlockUrl: process.env.REACT_APP_AUTOBLOCK_URL,
  cookiesSdkKey: process.env.REACT_APP_SDK_KEY,
}

export default config
